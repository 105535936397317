import { Component, Inject } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';

@Component({
  selector: 'bi-alert-tooltip-modal',
  templateUrl: './alert-tooltip-modal.component.html',
  styleUrls: ['./alert-tooltip-modal.component.scss'],
})
export class AlertTooltipModalComponent {
  constructor(
    public dialogRef: MatDialogRef<AlertTooltipModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}
}
