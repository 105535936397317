import { Component, Input } from '@angular/core';
import { dsAnimations } from '@design-system/cdk/animations';
import {
  SearchActions,
  SearchResult,
  SearchResultLink,
  SearchScopes,
  SearchSelector,
  SearchState,
} from '@features/search';
import { Store } from '@ngrx/store';
import {
  MessageSeverityType,
  MessageTargetType,
  MessengerService,
} from '@shared-lib/messenger';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'ds-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss'],
  animations: [dsAnimations.fade, dsAnimations.fadeIn],
})
export class SearchResultComponent {
  @Input() scope: string;
  @Input() paldeskBasePath: string;
  isSearchDone$: Observable<boolean>;
  searchResult$: Observable<SearchResult.Container>;
  readonly searchScopes = SearchScopes;

  constructor(
    private store: Store<SearchState>,
    private messageService: MessengerService,
  ) {
    this.searchResult$ = this.store.select(SearchSelector.searchResults);
    this.isSearchDone$ = this.store
      .select(SearchSelector.searchPending)
      .pipe(map((isSearchPending) => !isSearchPending));
  }

  storeSelectedResult(link: SearchResultLink) {
    this.store.dispatch(
      SearchActions.AddSearchResult({
        key: 'global-search',
        result: link,
      }),
    );
  }

  logSearchClick(searchId: string, searchCategory: string, index?: number) {
    if (index !== undefined) {
      const logData = {
        searchId: searchId,
        searchCategory: searchCategory,
        fromExpanded: index > 2,
      } as SearchResult.ItemClickLogData;
      this.messageService.sendDetailMessage({
        severity: MessageSeverityType.info,
        message: 'PaldeskSearch - result item clicked',
        detailObject: logData,
        source: 'SearchService',
        targets: [MessageTargetType.log],
      });
    }
  }
}
