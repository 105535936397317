import {
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Directive,
  ElementRef,
  EmbeddedViewRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import {
  MatBottomSheet,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { dsConfig } from '@design-system/cdk/config';
import { DateTime } from '@paldesk/shared-lib/utils/date-utils';
import { filterTruthy } from '@shared-lib/rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  Observable,
  ReplaySubject,
  startWith,
  Subject,
  takeUntil,
} from 'rxjs';
import { DsFilterV2DrawerComponent } from './filter-v2-drawer.component';

@Directive({ selector: '[dsFilterItem]' })
export class DsFilterItemDirective implements OnInit, OnDestroy {
  @Input() set dsFilterItem(val: AbstractControl) {
    if (val) {
      this.link(val);
    }
  }
  view: EmbeddedViewRef<any>;

  protected links: Set<AbstractControl> = new Set();
  destroy$ = new Subject<void>();
  private hasValue_ = new ReplaySubject<boolean>(1);
  hasValue$ = this.hasValue_.asObservable();
  private initial = true;

  get value(): any {
    const [control] = Array.from(this.links);
    return control?.value;
  }

  constructor(
    public templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    this.view = this.viewContainer.createEmbeddedView(this.templateRef);
    this.renderer.removeClass(this.view.rootNodes[0], 'full-width');
    this.renderer.addClass(this.view.rootNodes[0], 'ds-filter-item');
  }

  link(control: AbstractControl): void {
    control.valueChanges
      .pipe(startWith(this.value), takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          // use datepicker text instead of value (will be null) as long as user inputs
          const v =
            (control.errors && control.errors['matDatepickerParse']?.text) ||
            control.getRawValue();
          const hasVal =
            v instanceof Date ||
            DateTime.isNativeDate(v) ||
            (typeof v === 'number' && !!v) ||
            !!v?.length;

          if (hasVal || !this.initial) {
            this.hasValue_.next(hasVal);
          }

          this.initial = false;

          this.links.forEach((link) => {
            link.patchValue(v, {
              emitEvent: false,
              // do not update datepicker view whilst text input
              // otherwise it gets reset on every invalid date input
              emitModelToViewChange:
                control.errors && control.errors['matDatepickerParse']?.text
                  ? false
                  : true,
            });
          });
        },
      });
    this.links.add(control);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}

@Component({
  selector: 'ds-filter-wrapper',
  template: '',
})
export class FilterWrapperComponent {
  constructor(public dsFilterComponent: DsFilterV2Component) {}

  @ViewChildren(DsFilterItemDirective, { read: DsFilterItemDirective })
  set items(val: QueryList<DsFilterItemDirective>) {
    this.dsFilterComponent.addItems(val);
  }
}
@Component({
  selector: 'ds-filter-v2',
  templateUrl: './filter-v2.component.html',
  styleUrls: ['./filter-v2.component.scss'],
})
export class DsFilterV2Component implements OnDestroy {
  listItems: TemplateRef<any>[];
  drawerItems: DsFilterItemDirective[] = [];
  @Input() showApplyButton: boolean;
  @Output() resetFilter = new EventEmitter<void>();
  @Output() apply = new EventEmitter<void>();
  badge = 0;

  @ContentChildren(DsFilterItemDirective, { read: DsFilterItemDirective })
  set items_(val: QueryList<DsFilterItemDirective>) {
    this.addItems(val);
  }
  @ViewChild('resetBtn') resetBtn: ElementRef;
  @ViewChild('applyBtn') applyBtn: ElementRef;
  @ViewChild('applyBtnHelper') applyBtnHelper: any;
  @ViewChild('mainHolder') set mainHolder(value: ElementRef) {
    if (!this.resizeObserver && value) {
      new Observable((subscriber) => {
        this.resizeObserver = new ResizeObserver((entries) => {
          subscriber.next(entries[0].contentRect.width);
        });

        this.resizeObserver.observe(value.nativeElement, {
          box: 'border-box',
        });

        return () => {
          this.resizeObserver.unobserve(value.nativeElement);
          this.resizeObserver.disconnect();
        };
      })
        .pipe(
          debounceTime(50),
          distinctUntilChanged(),
          filterTruthy(),
          takeUntil(this.destroy$),
        )
        .subscribe((width) => {
          this.zone.run(() => {
            this.containerWidth = width as number;
            this.setItemsViewMode();
          });
        });
    }
  }

  resizeObserver: ResizeObserver;
  containerWidth: number;
  showAllFilter: boolean;
  bottomSheetRef?: MatBottomSheetRef<DsFilterV2DrawerComponent>;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private cd: ChangeDetectorRef,
    private zone: NgZone,
    private bottomSheet: MatBottomSheet,
  ) {}

  addItems(items: QueryList<DsFilterItemDirective>) {
    this.badge = 0;
    this.drawerItems = [...new Set([...this.drawerItems, ...items])];
    if (this.bottomSheetRef) {
      this.bottomSheetRef.instance.data.drawerItems = this.drawerItems.map(
        (x) => x.templateRef,
      );
    }

    items.forEach((x) => {
      x.hasValue$
        .pipe(
          distinctUntilChanged(),
          takeUntil(this.destroy$),
          takeUntil(x.destroy$),
        )
        .subscribe({
          next: (hasValue) => {
            if (hasValue) {
              this.badge++;
            } else if (this.badge > 0) {
              this.badge--;
            }
          },
        });
      x.destroy$.subscribe({ next: () => this.removeItem(x) });
    });
    this.setItemsViewMode();
  }

  removeItem(item: DsFilterItemDirective) {
    this.drawerItems = this.drawerItems.filter((itm) => itm !== item);
    this.setItemsViewMode();
  }

  setItemsViewMode() {
    let totalContentsWidth = 0;
    this.listItems = [];
    this.showAllFilter = this.drawerItems.length > 1;

    this.drawerItems.forEach((item) => {
      let totalWidthWithItem: number =
        totalContentsWidth +
        item.view.rootNodes[0].offsetWidth +
        dsConfig.spacing / 2;

      //reset button might appear if not yet visible
      if (!this.resetBtn) {
        totalWidthWithItem += 50;
      }
      // apply button might appear if not yet visible
      if (this.showApplyButton && !this.applyBtn) {
        totalWidthWithItem +=
          this.applyBtnHelper?._elementRef.nativeElement.offsetWidth ||
          0 + dsConfig.spacing / 2;
      }

      if (this.containerWidth > totalWidthWithItem) {
        this.listItems.push(item.templateRef);
        totalContentsWidth = totalWidthWithItem;
      } else {
        return;
      }
    });
    this.cd.detectChanges();
  }

  openAllFilters(): void {
    this.bottomSheetRef = this.bottomSheet.open(DsFilterV2DrawerComponent, {
      panelClass: 'filter-panel',
      data: {
        drawerItems: this.drawerItems.map((x) => x.templateRef),
        showApplyButton: this.showApplyButton,
      },
    });
    this.bottomSheetRef.instance.apply
      .pipe(takeUntil(this.destroy$))
      .subscribe({ next: () => this.apply.emit() });
    this.bottomSheetRef.instance.resetFilter
      .pipe(takeUntil(this.destroy$))
      .subscribe({ next: () => this.resetFilter.emit() });
    this.bottomSheetRef
      .afterDismissed()
      .subscribe(() => (this.bottomSheetRef = undefined));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
