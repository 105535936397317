import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { TranslateModule } from '@ngx-translate/core';
import { DsFilterV2DrawerComponent } from './filter-v2/filter-v2-drawer.component';
import {
  DsFilterItemDirective,
  DsFilterV2Component,
  FilterWrapperComponent,
} from './filter-v2/filter-v2.component';
import { DsFilterComponent } from './filter.component';

@NgModule({
  imports: [
    CommonModule,
    DsSpacingModule,
    FlexLayoutModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatCardModule,
    MatDividerModule,
    MatExpansionModule,
    MatIconModule,
    MatTooltipModule,
    TranslateModule,
  ],
  exports: [DsFilterComponent, DsFilterV2Component, DsFilterItemDirective],
  declarations: [
    DsFilterComponent,
    DsFilterV2Component,
    DsFilterItemDirective,
    DsFilterV2DrawerComponent,
    FilterWrapperComponent,
  ],
})
export class DsFilterModule {}
