import { Component, Input, OnInit } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { AlertTooltipModalComponent } from '../modals/alert-tooltip-modal/alert-tooltip-modal.component';
import { TooltipService } from '../services/tooltip.service';

@Component({
  selector: 'bi-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent implements OnInit {
  @Input() id: number;

  @Input() title = '';
  @Input() description = '';

  constructor(
    private dialog: MatDialog,
    private tooltipService: TooltipService,
  ) {}

  async ngOnInit() {
    // Needs timeout here since TooltipService is not fully available on initial load
    setTimeout(() => {
      if (this.id) {
        const tooltip = this.tooltipService.getById(this.id);
        if (tooltip) {
          this.title = tooltip.name;
          this.description = tooltip.description;
        }
      }
    }, 0);
  }

  public showDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: this.title,
      description: this.description,
    };
    this.dialog.open(AlertTooltipModalComponent, dialogConfig);
  }
}
