import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingPlaceholderComponent } from './loading-placeholder/loading-placeholder.component';
import { PendingButtonDirective } from './pending-button/pending-button.directive';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatIconModule } from '@angular/material/icon';
@NgModule({
  imports: [CommonModule, MatProgressSpinnerModule, MatIconModule],
  declarations: [LoadingPlaceholderComponent, PendingButtonDirective],
  exports: [LoadingPlaceholderComponent, PendingButtonDirective],
})
export class DsLoadingModule {}
