import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { SearchHistoryComponent } from './search-history/search-history.component';
import { SearchResultModule } from './search-result/search-result.module';
import { SearchComponent } from './search.component';
import { Angulartics2OnModule } from 'angulartics2';

@NgModule({
  imports: [
    CommonModule,
    MatInputModule,
    MatSelectModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    ReactiveFormsModule,
    RouterModule,
    FlexLayoutModule,
    SearchResultModule,
    DsSpacingModule,
    TranslateModule,
    Angulartics2OnModule,
  ],
  declarations: [SearchComponent, SearchHistoryComponent],
  exports: [SearchComponent],
})
export class SearchModule {}
