import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { IFeatureGroupEdit } from '../../admin/feature-groups/feature-groups.component';
import { FeatureGroupService } from '../../services/feature-group.service';

@Component({
  selector: 'bi-edit-feature-group-translation-modal',
  templateUrl: './edit-feature-group-translation-modal.component.html',
  styleUrls: ['./edit-feature-group-translation-modal.component.scss'],
})
export class EditFeatureGroupTranslationModalComponent implements OnInit {
  public editData: IFeatureGroupEdit;

  constructor(
    private featureGroupService: FeatureGroupService,
    public dialogRef: MatDialogRef<EditFeatureGroupTranslationModalComponent>,
    @Inject(MAT_DIALOG_DATA) private featureGroupId: number,
  ) {}

  async ngOnInit() {
    this.editData = await this.featureGroupService.editFeatureGroup(
      this.featureGroupId,
    );
  }

  public async update(form: NgForm, featureGroupId: number) {
    await this.featureGroupService.updateFeatureGroup(
      form.value,
      featureGroupId,
    );

    this.dialogRef.close();
  }
}
