import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Resolve } from '@angular/router';
import { AlertFeatureSelectionLossModalComponent } from '../../../../modals/alert-feature-selection-loss-modal/alert-feature-selection-loss-modal.component';
import { DataCollectionService } from '../../data-collection.service';

@Injectable()
export class DataCollectionResolver implements Resolve<boolean> {
  constructor(
    private dataCollectionService: DataCollectionService,
    private dialog: MatDialog,
  ) {}

  resolve() {
    return true;
  }

  public async openDialog(): Promise<any> {
    return new Promise((resolve, reject) => {
      const dialogRef = this.dialog.open(
        AlertFeatureSelectionLossModalComponent,
        {
          width: '30%',
        },
      );

      dialogRef.beforeClosed().subscribe((result: string) => {
        if (result === 'true') {
          resolve(true);
        }
      });
    });
  }
}
