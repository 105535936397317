import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { PdLetDirectiveModule } from '@cdk/pd-let';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { DsLoadingModule } from '@design-system/components/loading';
import { TranslateModule } from '@ngx-translate/core';
import { IsPdIconPipeModule } from '@shared-lib/is-pd-icon-pipe';
import { PlaceholderComponent } from './placeholder.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    FlexLayoutModule,
    DsSpacingModule,
    PdLetDirectiveModule,
    IsPdIconPipeModule,
    DsLoadingModule,
    MatButtonModule,
  ],
  exports: [PlaceholderComponent],
  declarations: [PlaceholderComponent],
})
export class DsPlaceholderModule {}
