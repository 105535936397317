<button
  class="mobile-close"
  *ngIf="drawer.opened"
  fxHide.gt-xs
  mat-icon-button
  (click)="drawer.close()"
>
  <mat-icon>close</mat-icon>
</button>
<mat-drawer-container class="full-height mat-typography">
  <mat-drawer #drawer [autoFocus]="false">
    <div class="drawer-container padding full-height">
      <div fxFlex="1 1 0" class="full-width">
        <ds-menu
          *ngIf="menu && !drawerPortal; else oldMenu"
          [menu]="menu"
          [menuLoadingCount]="menuLoadingCount"
          fxHide.gt-xs
          data-cy="ds-menu-mobile"
        ></ds-menu>
        <ng-template #oldMenu [cdkPortalOutlet]="drawerPortal"></ng-template>
      </div>
      <button
        fxHide.xs
        mat-icon-button
        (click)="drawer.close()"
        data-cy="close-drawer-button"
      >
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>
  </mat-drawer>
  <mat-drawer-content>
    <div
      fxLayout
      class="full-height"
      fxLayout.xs="column"
      [ngClass]="{ menu: !!menu }"
    >
      <ds-sidebar
        *ngIf="!hideAppBar"
        [hasNoLogIn]="hasNoLogIn"
        [helpIconUrl]="helpIconUrl"
        [fastFeedbackAppId]="fastFeedbackAppId"
        [hasSidebar]="hasSidebar"
        [helpOpenSameWindow]="helpOpenSameWindow"
        (userClick)="openDrawer($event)"
        class="print-hidden"
      >
      </ds-sidebar>
      <div
        fxFlex="1 1 auto"
        class="relative-position content-wrapper"
        [ngClass.xs]="'scroll-container-vertical'"
      >
        <div
          fxLayout
          class="full-height"
          *pdLet="isExpanded$ | async as isExpanded"
        >
          <aside
            fxHide.xs
            *ngIf="hasSidebar"
            [class.mini]="!isExpanded"
            [fxFlex]="(isExpanded ? 15 : 2) | dsSpacing"
            [fxFlex.xs]="isExpanded ? '80vw' : (2 | dsSpacing)"
            class="print-hidden"
          >
            <button
              mat-raised-button
              (click)="toggleSidebar(!isExpanded)"
              data-cy="toggle-sidebar-button"
            >
              <mat-icon
                [@indicatorRotate]="isExpanded ? 'expanded' : 'collapsed'"
              >
                chevron_right
              </mat-icon>
            </button>
            <ds-menu
              *ngIf="menu; else deprecatedMenu"
              [isExpanded]="isExpanded"
              [menu]="menu"
              [menuLoadingCount]="menuLoadingCount"
              data-cy="ds-menu"
            ></ds-menu>
            <ng-template #deprecatedMenu>
              <ds-page-sidebar-configuration
                [paldeskUrl]="paldeskUrl"
                [isExpanded]="isExpanded"
              ></ds-page-sidebar-configuration>
            </ng-template>
          </aside>
          <div
            fxFlex
            fxLayout="column"
            #pageContentWrapper
            class="page-content-wrapper relative-position full-height"
          >
            <paldesk-hc-status-banner></paldesk-hc-status-banner>
            <div
              class="scroll-container-vertical full-height"
              id="scrollToTopRestoration"
            >
              <div class="page-content" [class.no-sidebar]="!hasSidebar">
                <div
                  [@dsFadeIn]
                  [@dsFadeOut]
                  *ngIf="isExpanded"
                  class="backdrop cdk-overlay-dark-backdrop print-hidden"
                  fxHide
                  fxHide.xs
                  fxShow.lt-md
                  (click)="toggleSidebar(!isExpanded)"
                ></div>
                <ng-content> </ng-content>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
