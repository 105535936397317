import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {
  MessageSeverityType,
  MessageTargetType,
  MessengerService,
} from '@shared-lib/messenger';

@Component({
  templateUrl: './roles-removed-dialog.component.html',
})
export class RolesRemovedDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<RolesRemovedDialogComponent>,
    private messageService: MessengerService,
  ) {
    this.logDebug('Opening RolesRemovedDialog.');
  }

  reload() {
    this.logDebug('Reloading page from RolesRemovedDialog.');
    window.location.reload();
  }

  close() {
    this.logDebug('Closing RolesRemovedDialog.');
    this.dialogRef.close();
  }

  private logDebug(message: string) {
    this.messageService.sendDetailMessage({
      severity: MessageSeverityType.debug,
      message,
      source: 'RolesRemovedDialogComponent',
      targets: [MessageTargetType.log],
    });
  }
}
