import { Component, Inject } from '@angular/core';
import {
  MatLegacySnackBarRef as MatSnackBarRef,
  MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA,
} from '@angular/material/legacy-snack-bar';
import { DsTextIndicatorType } from '@design-system/components/text-indicator';
import { take } from 'rxjs/operators';
import { DsSnackbarData } from './snackbar.models';

@Component({
  selector: 'ds-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
})
export class DsSnackbarComponent {
  type: DsTextIndicatorType;
  message: string;
  action?: string;
  snackbarRef: MatSnackBarRef<DsSnackbarComponent>;
  href?: string;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: DsSnackbarData) {
    this.message = data.message;
    this.type = data.type || 'info';
    this.action = data.action || '';
    this.href = data.href;
    data.ref.pipe(take(1)).subscribe((ref) => (this.snackbarRef = ref));
  }
}
