<h4>
  <small>{{ 'header.search.result_documents' | translate }}</small>
</h4>
<mat-action-list dense *ngIf="documents">
  <ds-document-class
    *ngFor="
      let class of viewAll
        ? documents.document_classes
        : documents.document_classes.slice(0, 3);
      let itemIndex = index
    "
    [class]="class"
    [expanded]="class.document_class_code === expandedClassCode"
    [paldeskBasePath]="paldeskBasePath"
    [searchId]="searchId"
    (classClick)="toggleClass($event)"
    (documentClick)="itemClick.emit($event)"
  ></ds-document-class>
  <ds-view-toggle
    *ngIf="documents.document_classes.length > 3"
    (click)="toggleViewAll()"
    [allVisible]="viewAll"
  >
  </ds-view-toggle>
</mat-action-list>
