<strong fxLayout fxLayoutAlign="start center" fxHide.lt-md>
  <ng-container
    *ngFor="
      let breadcrumb of breadcrumbs;
      let first = first;
      let last = last;
      let i = index
    "
  >
    <span
      *pdLet="breadcrumb.label$ | async as label"
      fxLayout
      fxLayoutAlign="center center"
    >
      <ng-container *ngIf="!first"
        >&nbsp;<mat-icon>chevron_right</mat-icon>&nbsp;</ng-container
      >
      <a
        class="no-underline"
        *ngIf="!last; else lastbreadcrumb"
        [routerLink]="['./' + breadcrumb.url]"
        data-test="breadcrumb"
      >
        <ng-container *ngIf="label; else labelLoading">
          {{
            breadcrumb?.supressTitleCase
              ? (label | translate)
              : (label | translate | titlecase)
          }}
        </ng-container>
        <ng-template #labelLoading>...</ng-template>
      </a>
      <ng-template #lastbreadcrumb>
        <ng-container *ngIf="label; else labelLoading"
          ><span data-test="breadcrumb">
            {{
              breadcrumb?.supressTitleCase
                ? (label | translate)
                : (label | translate | titlecase)
            }}
          </span>
        </ng-container>
        <ng-template #labelLoading>...</ng-template>
      </ng-template>
    </span>
  </ng-container>
</strong>

<strong fxHide fxShow.lt-md fxLayoutAlign="start center">
  <ng-container
    *ngFor="let breadcrumb of breadcrumbs; let first = first; let i = index"
  >
    <ng-container
      *ngIf="i === breadcrumbs.length - 2 || i === breadcrumbs.length - 3"
    >
      <span
        *pdLet="breadcrumb.label$ | async as label"
        fxLayout
        fxLayoutAlign="center center"
      >
        <ng-container *ngIf="!first"
          >&nbsp;<mat-icon fxLayoutAlign="center center">chevron_left</mat-icon
          >&nbsp;</ng-container
        >
        <a
          class="no-underline"
          [routerLink]="['./' + breadcrumb.url]"
          data-test="breadcrumb"
        >
          <ng-container *ngIf="label; else labelLoading">
            {{
              breadcrumb?.supressTitleCase
                ? (label | translate)
                : (label | translate | titlecase)
            }}
          </ng-container>
          <ng-template #labelLoading>...</ng-template>
        </a>
      </span>
    </ng-container>
  </ng-container>
</strong>
