import { Component, EventEmitter, Input, Output } from '@angular/core';
import { matExpansionAnimations } from '@angular/material/expansion';
import { UserService } from '@features/auth';
import {
  SearchResult,
  SearchResultLink,
  SearchSelector,
  SearchState,
} from '@features/search';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { DocumentUrlPipe } from '../document-url.pipe';

@Component({
  selector: 'ds-document-class',
  templateUrl: './document-class.component.html',
  styleUrls: ['./document-class.component.scss'],
  animations: [
    matExpansionAnimations.bodyExpansion,
    matExpansionAnimations.indicatorRotate,
  ],
})
export class DocumentClassComponent {
  @Input() class: SearchResult.DocumentClass;
  @Input() expanded = false;
  @Input() paldeskBasePath: string;
  @Input() searchId: string;
  @Output() classClick = new EventEmitter<string>();
  @Output() documentClick = new EventEmitter<SearchResultLink>();

  readonly userLang;
  readonly searchTerm$: Observable<string>;

  constructor(
    private userService: UserService,
    private store: Store<SearchState>,
  ) {
    this.userLang = this.userService.userContext.lang;
    this.searchTerm$ = this.store.select(SearchSelector.searchTerm);
  }

  toggleClass(classCode: string) {
    this.classClick.emit(classCode);
  }

  itemClick(document: SearchResult.TopDocument, index: number) {
    this.documentClick.emit({
      index: index,
      name: document.title,
      description: '',
      url: new DocumentUrlPipe().transform(document.url, this.userLang),
    } as SearchResultLink);
  }
}
