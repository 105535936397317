<mat-list-item (click)="toggleCategory(category.id)" *ngIf="category">
  <div fxLayout fxLayoutAlign="start center" class="full-width">
    <span fxFlex="1 1 auto">{{ category.name }}</span>
    <span fxFlex="0 0 auto">{{ category.products.length }}</span>
    <mat-icon
      fxFlex="0 0 auto"
      [@indicatorRotate]="expanded ? 'expanded' : 'collapsed'"
      >keyboard_arrow_down</mat-icon
    >
  </div>
</mat-list-item>
<div
  [style.padding-left]="0.5 | dsSpacing"
  [@bodyExpansion]="expanded ? 'expanded' : 'collapsed'"
  *ngIf="category"
>
  <a
    *ngFor="
      let product of viewAll
        ? category.products
        : category.products.slice(0, 3);
      let indexOfItem = index
    "
    mat-list-item
    [href]="
      paldeskBasePath +
      '/product;' +
      'category=' +
      category.code +
      ';product=' +
      product.code
    "
    (click)="
      productClick.emit({
        index: indexOfItem,
        name: product.name,
        description: '',
        url:
          '/product;' + 'category=' + category.code + ';product=' + product.code
      })
    "
    target="_blank"
  >
    {{ product.name }}
  </a>
  <ds-view-toggle
    *ngIf="category.products.length > 3"
    (click)="toggleViewAll()"
    [allVisible]="viewAll"
  >
  </ds-view-toggle>
</div>
