<div fxLayout="column" [fxLayoutGap]="1 | dsSpacing" class="full-height">
  <div fxFlex="0 0 auto">
    <h1 translate>ADMIN.SUB_NAV.FEATURES</h1>
    <mat-card class="full-width">
      <mat-form-field class="search text-right">
        <input type="search" matInput placeholder="" [(ngModel)]="searchQ" />
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
    </mat-card>
  </div>

  <ds-table fxFlex="0 1 auto">
    <table class="pd-table">
      <thead>
        <tr class="mat-header-row">
          <th class="mat-header-cell mat-table-sticky" translate>
            ADMIN.FEATURES.FEATURE
          </th>
          <th
            class="mat-header-cell mat-action-cell mat-table-sticky text-center"
            translate
          >
            ADMIN.OPTIONS
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let feature of filterFeatures()" class="mat-row">
          <td>{{ feature.name }}</td>
          <td class="min-width-cell mat-cell mat-action-cell mat-table-sticky">
            <button mat-button (click)="openDialog(feature)">
              {{ 'G.EDIT' | translate }}
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </ds-table>
</div>
