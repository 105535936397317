<div fxLayout="column">
  <h4>{{ 'design_system.filter.filter' | translate }}</h4>
  <div
    *ngFor="let item of data.drawerItems"
    class="wrap"
    (keydown.enter)="apply.emit()"
  >
    <ng-template [ngTemplateOutlet]="item"></ng-template>
  </div>
  <div
    fxFlex="auto"
    fxLayout="row"
    [fxLayoutGap]="0.5 | dsSpacing"
    fxLayoutAlign="space-between baseline"
    class="buttons"
  >
    <button
      mat-stroked-button
      (click)="resetFilter.emit()"
      class="reset"
      data-cy="reset-filter-button"
    >
      <mat-icon inline>clear</mat-icon>
      {{ 'design_system.filter.clear_all' | translate }}
    </button>
    <button
      mat-raised-button
      color="primary"
      *ngIf="data.showApplyButton; else closeBtn"
      (click)="apply.emit(); this.bottomSheetRef.dismiss()"
      data-cy="apply-filter-button"
    >
      {{ 'design_system.filter.apply' | translate }}
    </button>
    <ng-template #closeBtn>
      <button
        mat-button
        (click)="this.bottomSheetRef.dismiss()"
        data-cy="close-filter-button"
      >
        {{ 'design_system.filter.close' | translate }}
      </button>
    </ng-template>
  </div>
</div>
