import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { IFeatureEdit } from '../../admin/features/features.component';
import { FeatureService } from '../../services/feature.service';

@Component({
  selector: 'bi-edit-feature-translation-modal',
  templateUrl: './edit-feature-translation-modal.component.html',
  styleUrls: ['./edit-feature-translation-modal.component.scss'],
})
export class EditFeatureTranslationModalComponent implements OnInit {
  public editData: IFeatureEdit;

  constructor(
    private featureService: FeatureService,
    public dialogRef: MatDialogRef<EditFeatureTranslationModalComponent>,
    @Inject(MAT_DIALOG_DATA) private featureId: number,
  ) {}

  async ngOnInit() {
    this.editData = await this.featureService.editFeature(this.featureId);
  }

  public async update(form: NgForm, featureId: number) {
    await this.featureService.updateFeature(form.value, featureId);

    this.dialogRef.close();
  }
}
