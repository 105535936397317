import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatStepperModule } from '@angular/material/stepper';
import { RouterModule } from '@angular/router';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { TranslateModule } from '@ngx-translate/core';
import {
  DsFullPageBodyDirective,
  DsFullPageComponent,
  DsFullPageFooterDirective,
  DsFullPageHeaderDirective,
} from './full-page/full-page.component';
import { DsPageActionDirective } from './page-actions/page-action.directive';
import { DsPageActionsComponent } from './page-actions/page-actions.component';
import { DsPageSidebarHeaderComponent } from './page-sidebar-header/page-sidebar-header.component';
import { DsPageSidebarItemComponent } from './page-sidebar-item/page-sidebar-item.component';
import { DsPageSidebarConfigurationComponent } from './page-sidebar/page-sidebar-configuration/page-sidebar-configuration.component';
import { DsPageSidebarContentComponent } from './page-sidebar/page-sidebar-content/page-sidebar-content.component';
import {
  DsPageSidebarBodyDirective,
  DsPageSidebarComponent,
} from './page-sidebar/page-sidebar.component';
import { DsPageStickyHeaderComponent } from './page-sticky-header/page-sticky-header.component';
@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    DsSpacingModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    PortalModule,
    RouterModule,
    MatStepperModule,
    MatMenuModule,
    TranslateModule,
  ],
  declarations: [
    DsPageSidebarItemComponent,
    DsPageSidebarComponent,
    DsPageSidebarContentComponent,
    DsFullPageComponent,
    DsFullPageHeaderDirective,
    DsFullPageFooterDirective,
    DsFullPageBodyDirective,
    DsPageSidebarConfigurationComponent,
    DsPageSidebarHeaderComponent,
    DsPageSidebarBodyDirective,
    DsPageStickyHeaderComponent,
    DsPageActionsComponent,
    DsPageActionDirective,
  ],
  exports: [
    DsPageSidebarItemComponent,
    DsPageSidebarConfigurationComponent,
    DsFullPageComponent,
    DsFullPageHeaderDirective,
    DsFullPageFooterDirective,
    DsFullPageBodyDirective,
    DsPageSidebarHeaderComponent,
    DsPageSidebarComponent,
    DsPageSidebarBodyDirective,
    DsPageStickyHeaderComponent,
    DsPageActionsComponent,
    DsPageActionDirective,
  ],
})
export class DsPageModule {}
